<template>
  <VehiclesTable
    :items="vehicles"
    :total-size="totalSize"
    :page-size="pageSize"
    :page-number="pageNumber"
    :loading="isLoading"
    :features="features"
    :title="$t('vehicles.table.title')"
    @pageChanged="pageChanged"
    @itemCreated="onItemCreated"
    @itemUpdated="onItemUpdated"
    @itemDeleted="onItemDeleted"
    @sortingChanged="onSortingChanged"
    @refreshRequested="onRefreshRequested"
  />
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import VehiclesTable from '@/components/tables/VehiclesTable'

export default {
  components: { VehiclesTable },
  data () {
    return {
      vehicles: [],
      isLoading: true,
      totalSize: 0,
      pageSize: 25,
      pageNumber: 1,
      latestSorting: undefined
    }
  },
  computed: {
    ...mapGetters('account', ['isOverlord', 'isEmployee']),
    features () {
      if (this.isOverlord) return ['create', 'update', 'delete']
      else if (this.isEmployee) return ['create', 'update']
      else return []
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('vehicles', ['fetchVehicles']),
    onItemUpdated (updatedVehicle) {
      // Try to find the updated vehicle among the currently shown vehicles.
      const index = this.vehicles.findIndex((vehicle) => vehicle.id === updatedVehicle.id)
      if (index !== -1) {
        // Update the vehicle to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.vehicles, index, updatedVehicle)
      }
    },
    async onItemDeleted (deletedVehicle) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onItemCreated (newVehicle) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onRefreshRequested () {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async pageChanged (newPageNumber) {
      // Update the current page number.
      this.pageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onSortingChanged (newSorting) {
      this.latestSorting = newSorting
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    fetchPageDebounced: debounce(async function (pageNumber, pageSize, sorting) {
      await this.fetchPage(pageNumber, pageSize, sorting)
    }, 250),
    async fetchPage (pageNumber, pageSize, sorting) {
      this.isLoading = true
      try {
        // Fetch all vehicles.
        const pagedResponse = await this.fetchVehicles({ pageNumber, pageSize, sorting })
        this.vehicles = pagedResponse.items
        this.totalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  created () {
    this.pageNumber = parseInt(this.$route.query.page || this.pageNumber)
  },
  async mounted () {
    await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
  },
  watch: {
    pageNumber (newVal, oldVal) {
      // Do nothing if the route is already correct.
      if (this.$route.query.page && parseInt(this.$route.query.page) === newVal) return
      // Replace the page number in the route/url to be the new page number.
      const query = { ...this.$route.query, page: newVal }
      this.$router.replace({ query })
    }
  }
}
</script>
